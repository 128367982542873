import { getUserData } from '@/auth/utils'

export const prepareApplicationSubmissionMessage = {
  methods: {
    prepareMessage(programName) {
      let message = ''

      if (this.$config['application-submission-message'][getUserData().role]) {
        message = this.$config['application-submission-message'][getUserData().role]
      }

      // eslint-disable-next-line
      let results = message.match("{{.*}}")

      if (programName && results !== null) {
        results.forEach(param => {
          message = message.replace(param, programName)
        })
      }

      return message
    },
  },
}

export const _ = null
