<template>
  <div>
    <div class="site__centered">
      <div class="tasks">
      <div class="tasks__header">
        <h2 class="tasks__title mobile-hidden">My Tasks</h2>
        <!-- <div class="message__success" v-if="showThankYou && applicationProgramName">
          {{ this.prepareMessage(this.applicationProgramName) }}
        </div> -->
      </div>
      <div class="tasks__body">
        <SideNav />
        <div class="tasks__row__col-9 tasks__row__col-sm-12">

          <div class="tasks__block to-do">

            <div class="tasks__block-header mobile-hidden">
              <div class="col-5">Tasks</div>
              <div class="col-5">{{ camperStudent }} / Program Name</div>
              <div class="col-2">Status</div>
            </div>

            <div class="tasks__block-row" v-for="(task, index) in tasksList" :key="index">
              <div class="col-5 tasks__block-title">
                <div class="tasks__block-icon">
                  <svg v-if="task.status === 'Overdue'"
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V5C0 5.53043 0.210714 6.03914 0.585786 6.41421C0.960859 6.78929 1.46957 7 2 7H5C5.53043 7 6.03914 6.78929 6.41421 6.41421C6.78929 6.03914 7 5.53043 7 5V2C7 1.46957 6.78929 0.960859 6.41421 0.585786C6.03914 0.210714 5.53043 0 5 0H2ZM2 2H5V5H2V2ZM10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3C9 3.26522 9.10536 3.51957 9.29289 3.70711C9.48043 3.89464 9.73478 4 10 4H15C15.2652 4 15.5196 3.89464 15.7071 3.70711C15.8946 3.51957 16 3.26522 16 3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H10ZM10 11C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H10ZM0 11C0 10.4696 0.210714 9.96086 0.585786 9.58579C0.960859 9.21071 1.46957 9 2 9H5C5.53043 9 6.03914 9.21071 6.41421 9.58579C6.78929 9.96086 7 10.4696 7 11V14C7 14.5304 6.78929 15.0391 6.41421 15.4142C6.03914 15.7893 5.53043 16 5 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V11ZM5 11H2V14H5V11Z" fill="#B63D55"/>
                  </svg>
                  <svg v-else-if="task.status === 'Completed'"
                       xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V5C0 5.53043 0.210714 6.03914 0.585786 6.41421C0.960859 6.78929 1.46957 7 2 7H5C5.53043 7 6.03914 6.78929 6.41421 6.41421C6.78929 6.03914 7 5.53043 7 5V2C7 1.46957 6.78929 0.960859 6.41421 0.585786C6.03914 0.210714 5.53043 0 5 0H2ZM2 2H5V5H2V2ZM10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3C9 3.26522 9.10536 3.51957 9.29289 3.70711C9.48043 3.89464 9.73478 4 10 4H15C15.2652 4 15.5196 3.89464 15.7071 3.70711C15.8946 3.51957 16 3.26522 16 3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H10ZM10 11C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H10ZM0 11C0 10.4696 0.210714 9.96086 0.585786 9.58579C0.960859 9.21071 1.46957 9 2 9H5C5.53043 9 6.03914 9.21071 6.41421 9.58579C6.78929 9.96086 7 10.4696 7 11V14C7 14.5304 6.78929 15.0391 6.41421 15.4142C6.03914 15.7893 5.53043 16 5 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V11ZM5 11H2V14H5V11Z" fill="#3DB649"/>
                  </svg>
                  <svg v-else
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       viewBox="0 0 16 16"
                       fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V5C0 5.53043 0.210714 6.03914 0.585786 6.41421C0.960859 6.78929 1.46957 7 2 7H5C5.53043 7 6.03914 6.78929 6.41421 6.41421C6.78929 6.03914 7 5.53043 7 5V2C7 1.46957 6.78929 0.960859 6.41421 0.585786C6.03914 0.210714 5.53043 0 5 0H2ZM2 2H5V5H2V2ZM10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3C9 3.26522 9.10536 3.51957 9.29289 3.70711C9.48043 3.89464 9.73478 4 10 4H15C15.2652 4 15.5196 3.89464 15.7071 3.70711C15.8946 3.51957 16 3.26522 16 3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H10ZM10 11C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H10ZM0 11C0 10.4696 0.210714 9.96086 0.585786 9.58579C0.960859 9.21071 1.46957 9 2 9H5C5.53043 9 6.03914 9.21071 6.41421 9.58579C6.78929 9.96086 7 10.4696 7 11V14C7 14.5304 6.78929 15.0391 6.41421 15.4142C6.03914 15.7893 5.53043 16 5 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V11ZM5 11H2V14H5V11Z" fill="#7D1E36"/>
                  </svg>
                </div>

                <div class="tasks__block-title-name">
                  <h4>{{ task.name }}</h4>
                  <span
                          class="col-5 tasks__block-date"
                          :class="{'danger' : task.status === 'Overdue', 'completed': task.status === 'Completed'}"
                  >Due date: {{ task.date | formatDate }}</span>

                  <div
                    class="overdue"
                    :class="{'danger' : task.status === 'Overdue'}"
                    v-if="task.status === 'Overdue'"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                      <path d="M4.9125 1.16663H10.0875L13.75 4.58496V9.41496L10.0875 12.8333H4.9125L1.25 9.41496V4.58496L4.9125 1.16663Z" fill="#B63D55"/>
                      <path d="M7.5 4.66663V6.99996" stroke="#FDF4F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.5 9.33337H7.50625" stroke="#FDF4F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Overdue</span>
                  </div>
                </div>

              </div>
              <div 
                class="col-5"
              >
                {{ task.camper_name }} / {{ task.program_name }}
              </div>
              <div
                v-if="task.status === 'Completed'"
                class="col-2 tasks__block-completed"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M7 14.0009C10.866 14.0009 14 10.8668 14 7.00085C14 3.13486 10.866 0.000854492 7 0.000854492C3.13401 0.000854492 0 3.13486 0 7.00085C0 10.8668 3.13401 14.0009 7 14.0009Z" fill="#3DB649"/>
                  <path d="M10 4.00085L5.875 9.00085L4 6.72813" stroke="#F4FDF5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Completed</span>
              </div>
              <router-link
                v-else
                :to="{ name: 'application-task-create', params: { id: task.id, application_id: task.application_id } }"
              >
                <div
                  class="btn btn--color-dark tasks__block-button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title/><path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z"/></svg>
                  Complete Form
                </div>
              </router-link>
            </div>

          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

  import axios from '@axios'
  import SideNav from '@/components/site/SideNav.vue'
  import { getUserData } from '@/auth/utils'
  import { prepareApplicationSubmissionMessage } from '@core/mixins/prepareApplicationSubmissionMessage'

  import storeModule from '@/views/Tasks/tasksStoreModule'
  import store from '@/store'
  import { onUnmounted } from '@vue/composition-api'

  export default {
    name: "Tasks",
    components: {
      SideNav,
    },
    mixins: [prepareApplicationSubmissionMessage, instanceSessionCamperNames],
    filters: {
      formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [month, day, year].join('-');
      }
    },
    data() {
      return {
        isPopupOpened: false,
        user: getUserData(),
        tasksList: [],
        showThankYou: false,
        applicationProgramName: '',
      }
    },
    setup() {
      const INVOICE_APP_STORE_MODULE_NAME = 'app-tasks'

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      })
    },
    async created() {
      store.commit('SET_LOADING_STATUS', true)

      this.$emit('loading', true)
      await store.dispatch('app-tasks/fetchTasksList').then(response => {
        this.tasksList = response.data.data
        this.$emit('loading', false)
      }).finally(() => {
        this.$emit('loading', false)
      })
      store.commit('SET_LOADING_STATUS', false)
    },
    methods: {
      async getApplicationName(id) {
        axios
          .get(`/applications/${id}`)
          .then(response => {
            this.applicationProgramName = response.data.data.program
          })
          .catch(error => console.log(error))
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if(from.name === "application-create"){
          vm.getApplicationName(to.query.id)
          vm.showThankYou = true
        }
      });
    }
  };
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.tasks {
  width: 100%;
  // &__title {
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 36px;
  //   line-height: 44px;
  //   color: #BA667C;
  //   margin-bottom: 40px;
  // }
  &__body {
    display: flex;
  }
  &__block {
    width: 100%;
    // margin-left: 50px;
    padding: 50px;
    background: #FFFFFF;
    border: 1px solid #ECEFF9;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 70px;
    &-header {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: $secondary;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($secondary, 0.067);
    }
    &-row {
      display: flex;
      align-items: center;
      padding: 45px 0;
      border-bottom: 1px solid rgba($secondary, 0.067);
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      .btn {
        max-width: 125px;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          fill: #fff;
        }
      }
    }
    &-title {
      display: flex;
      align-items: flex-start;
      position: relative;

      h4 {
        margin-bottom: 8px;
        font-size: 18px;
        color: $secondary;
      }
    }
    &-date {
      margin-bottom: 8px;
    }
    &-review {
      display: flex;
      flex: 0 0 12% !important;
      align-items: center;
      justify-content: center;
      width: 114px;
      height: 30px;
      background: #FFFBE5;
      border-radius: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #F1A348;
      span {
        display: inline-flex;
        margin-left: 10px;
      }
    }
    &-provided {
      div {
        display: flex;
        align-items: center;
        width: 230px;
        padding: 10px 20px;
        background: #F0F2FF;
        border-radius: 6px;
        svg {
          width: 13px !important;
          height: 17px !important;
        }
        span {
          margin-left: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
      }
    }
    &-completed {
      display: flex;
      flex: 0 0 12% !important;
      align-items: center;
      justify-content: center;
      width: 125px;
      height: 30px;
      background: #F4FDF5;
      border-radius: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #3DB649;
      span {
        display: inline-flex;
        margin-left: 10px;
      }
    }
    &-icon {
      width: 16px;
      margin-right: 24px;
    }
  }
}
.overdue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 26px;
  background: #FDF4F5;
  border-radius: 20px;
  color: #B63D55;
  span {
    margin-left: 5px;
  }
}
.danger {
  color: #B63D55;
}
.completed {
  color: #3DB649;
}
.col-5 {
  flex: 0 0 41.666666%;
  max-width: 41.666666%;
}
.col-2 {
  flex: 0 0 15%;
  max-width: 15%;
}
.overlay:before {
  display: none;
  content: '';
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 700px;
  padding: 65px 110px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  &-close {
    position: absolute;
    cursor: pointer;
    width: 10px;
    height: 10px;
    right: 20px;
    top: 20px;
    &:after, &:before {
      position: absolute;
      content: ' ';
      height: 14px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.opened {
  display: block;
  &:before {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .message__success {
    font-size: 16px;
    line-height: 20px;
    padding: 20px;
    white-space: pre-wrap;
  }
  .tasks {
    &__body {
      flex-direction: column;
    }
    &__block {
      padding: 0 30px 30px;
      &-row {
        flex-direction: column;
        align-items: start;
        &:first-child {
          padding-top: 0;
        }
      }
      & a {
        width: 100%;
      }
      &-button{
        width: 100%;
        max-width: 100% !important;
      }
      &-completed {
        flex: 0 0 100% !important;
        width: 100%;
        max-width: 100%;
        height: 30px;
        background: #F4FDF5;
        border-radius: 20px;
        font-size: 16px;
        line-height: 20px;
        color: #3DB649;
        span {
          display: inline-flex;
          margin-left: 10px;
        }
      }
    }
  }
  .col-5 {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}
</style>
